import React from 'react';
import { Link } from 'gatsby';
import { Button } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import '../styles/custom-button.scss';

const CustomButton = ({ children, variant, to, ...otherProps }) => (
  <Link to={to}>
    <Button
      className={`custom-button ${
        variant === 'crystal' ? 'button-crystal' : null
      }`}
      fontSize="sm"
      fontWeight={600}
      color="#044606"
      bg={variant === 'crystal' ? '#FFFFFF' : '#7fba00'}
      _hover={{
        bg: '#044606',
        color: 'white',
      }}
      {...otherProps}
      rightIcon={<ChevronRightIcon w={8} h={8} />}
    >
      <h5>{children}</h5>
    </Button>
  </Link>
);

export default CustomButton;
