import React, { useState, useReducer } from 'react';
import {
  Container,
  Box,
  Spacer,
  Input,
  Icon,
  InputGroup,
  SimpleGrid,
  Button,
} from '@chakra-ui/react';
import emailjs from 'emailjs-com';
import ReCAPTCHA from 'react-google-recaptcha';
import { StaticImage } from 'gatsby-plugin-image';
import { EmailIcon, ArrowForwardIcon } from '@chakra-ui/icons';

import {
  FaTwitter,
  FaFacebookF,
  FaLinkedinIn,
  FaYoutube,
} from 'react-icons/fa';
import { Link } from 'gatsby';
import '../styles/footer.scss';

const initialState = {
  email: '',
};

function reducer(state, action) {
  switch (action.type) {
    case 'email':
      return { ...state, email: action.value };
    default:
      throw new Error();
  }
}

const Footer = () => {
  const [formState, dispatch] = useReducer(reducer, initialState);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevents default refresh by the browser
    const regex = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}');
    if (regex.test(e.target[0].value)) {
      setShowCaptcha(true);
      return true;
    }
    alert('You have entered an invalid email address!');
    return false;
  };

  const sendEmail = (captchaValue) => {
    const params = {
      ...formState,
      'g-recaptcha-response': captchaValue,
    };
    emailjs
      .send(
        `service_3z3x4r8`,
        `template_atuhcsa`,
        params,
        process.env.GATSBY_EMAILJS_USER_ID
      )
      .then(
        (result) => {
          alert('Message Sent, We will get back to you shortly', result.text);
        },
        (error) => {
          alert('An error occurred, Please try again', error.text);
        }
      );
  };

  return (
    <Box w="100%" p={4}>
      <Container maxW={1400} className="footer">
        <Box className="footer-menu">
          <SimpleGrid
            columns={{ sm: 1, md: 2, lg: 4 }}
            spacing={10}
            className="footer-menu--main"
          >
            <ul className="footer-menu--main__items">
              <li>CONTACT</li>
              <li className="contact">
                <EmailIcon />
                <a href="mailto:info@ostara.com"> info@ostara.com</a>
              </li>
              <li className="contact">
                <Icon as={FaTwitter} />{' '}
                <a
                  href="https://twitter.com/CG_EfficientP"
                  target="_blank"
                  rel="noreferrer"
                >
                  Twitter Crystal Green
                </a>
              </li>
              <li className="contact">
                <Icon as={FaTwitter} />{' '}
                <a
                  href="https://twitter.com/OstaraTech"
                  target="_blank"
                  rel="noreferrer"
                >
                  Twitter Ostara
                </a>
              </li>
              <li className="contact social-media">
                <a
                  href="https://www.facebook.com/Crystal-Green-by-Ostara-103006908502912"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icon as={FaFacebookF} />
                </a>
                <a
                  href="https://www.linkedin.com/company/ostara-nutrient-recovery-technolgies/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icon as={FaLinkedinIn} />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCr1PISdkFpf4ZfGN2cT3lXQ"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icon as={FaYoutube} />
                </a>
              </li>
            </ul>
            <ul className="footer-menu--main__items">
              <li>FERTILIZER</li>
              <li>
                <span>
                  <Link to="/product">Products</Link>
                </span>
              </li>
              <li>
                <span>
                  <Link to="/product/crystal-green">Crystal Green</Link>
                </span>
              </li>
              <li>
                <span>
                  <Link to="/product/crystal-green-pearl">
                    Crystal Green Pearl
                  </Link>
                </span>
              </li>
            </ul>
            <ul className="footer-menu--main__items">
              <li>
                <Link to="/solutions">NUTRIENT RECOVERY</Link>
              </li>
              <li>
                <Link to="/sustainability">SUSTAINABILITY</Link>
              </li>
              <li>
                <Link to="/data-insights">DATA & INSIGHTS</Link>
              </li>
              <li>
                <Link to="/company">ABOUT</Link>
              </li>
              <li>
                <Link to="/careers">CAREERS</Link>
              </li>
              <li>
                <Link to="/contact">CONTACT</Link>
              </li>
            </ul>
            <Box w="400px" className="subscribe-box">
              <p>Sign up to Learn More</p>
              {!showCaptcha ? (
                <form onSubmit={handleSubmit}>
                  <InputGroup>
                    <Input
                      name="email"
                      type="email"
                      placeholder="Email"
                      onChange={(e) =>
                        dispatch({ type: 'email', value: e.target.value })
                      }
                      required
                    />
                    <Button
                      className="submit-button"
                      rightIcon={<ArrowForwardIcon />}
                      colorScheme="transparent"
                      variant="outline"
                      type="submit"
                    />
                  </InputGroup>
                </form>
              ) : (
                <ReCAPTCHA
                  sitekey={process.env.GATSBY_RECAPTCHA_SITEKEY}
                  onChange={sendEmail}
                />
              )}
            </Box>
          </SimpleGrid>
          <Spacer />
        </Box>

        <Box className="footer-credit">
          <SimpleGrid columns={{ sm: 1, md: 2 }}>
            <Box>
              <p>{new Date().getFullYear()} © Ostara | All Rights Reserved</p>
              <Link to="/policy">Privacy Policy</Link> |{' '}
              <Link to="/terms-and-conditions">Terms Of Service</Link>
            </Box>
            <Box className="logo">
              <p>Site by</p>
              <a
                href="https://creativepace.com"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  placeholder="transparent"
                  layout="fixed"
                  src="../images/Pace_Creative.png"
                  alt="Ostara logo"
                  height={16}
                />
              </a>
            </Box>
          </SimpleGrid>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
