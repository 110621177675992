import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Header from './header';
import Footer from './footer';
import Transition from './transition';
import '../styles/global.scss';

const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <section className="page-section">
        <main className="page-container">
          <Transition location={location}>{children}</Transition>
        </main>
      </section>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

Layout.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  location: {},
};

export default Layout;
