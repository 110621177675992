/* eslint-disable react/no-array-index-key */
/* eslint-disable prettier/prettier */
import React from 'react';
import { Link } from 'gatsby';
import {
  Box,
  Collapse,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Container,
  Flex,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import logo from '../assets/ostara-logo.svg';
import CustomButton from './custom-button';
import '../styles/header.scss';

const NAV_ITEMS = [
  {
    label: 'FERTILIZER',
    children: [
      {
        label: 'Products',
        href: '/product',
        items: [
          {
            subLabel: 'Crystal Green',
            href: '/product/crystal-green',
          },
          {
            subLabel: 'Crystal Green Pearl',
            href: '/product/crystal-green-pearl',
          },
        ],
      },
      {
        label: 'Benefits',
        href: '/benefits',
        items: [
          {
            subLabel: 'Improve Yield',
            href: '/applications/improve-yield',
          },
          {
            subLabel: 'Reduce Nutrient Loss',
            href: '/applications/reduce-nutrient-loss',
          },
          {
            subLabel: 'Prevent Soil Fixation',
            href: '/applications/prevent-soil-fixation',
          },
          {
            subLabel: 'Turf & Ornamental',
            href: '/applications/turf-ornaments',
          },
        ],
      },
      {
        label: 'Nutrients',
        href: '/nutrients',
        items: [
          {
            subLabel: 'Phosphate',
            href: '/nutrients/phosphate',
          },
          {
            subLabel: 'Magnesium',
            href: '/nutrients/magnesium',
          },
          {
            subLabel: 'Nitrogen',
            href: '/nutrients/nitrogen',
          },
        ],
      },
    ],
  },
  {
    label: 'NUTRIENT RECOVERY',
    children: [
      {
        label: 'Solutions',
        href: '/solutions',
      },
      {
        label: 'Municipalities, Industries & More',
        href: '/municipalities',
      },
    ],
  },
  {
    label: 'SUSTAINABILITY',
    href: '/sustainability',
  },
  {
    label: 'DATA & INSIGHTS',
    href: '/data-insights',
  },
  {
    label: 'ABOUT',
    children: [
      {
        label: 'Who we are',
        href: '/company',
      },
      {
        label: 'Innovation',
        href: '/innovation',
      },
      {
        label: 'Management',
        href: '/management',
      },
    ],
  },
  {
    label: 'CAREERS',
    href: '/careers',
  },
];
export default function Header() {
  const { isOpen, onToggle } = useDisclosure();
  if (typeof window !== 'undefined') {
    if (!isOpen) {
      // eslint-disable-next-line no-undef
      document.body.style = 'overflow-y: scroll';
    } else {
      // eslint-disable-next-line no-undef
      document.body.style = 'overflow-y: hidden';
    }
  }

  return (
    <Box>
      <Flex
        minH="60px"
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle="solid"
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align="center"
        className="header"
      >
        <Container className="header-container" maxW={1400}>
          <Flex
            flex={{ base: 1, lg: 'auto' }}
            ml={{ base: -2 }}
            display={{ base: 'flex', lg: 'none' }}
          >
            <IconButton
              onClick={onToggle}
              icon={
                isOpen ? (
                  <CloseIcon w={3} h={3} />
                ) : (
                  <HamburgerIcon w={5} h={5} />
                )
              }
              variant="ghost"
              aria-label="Toggle Navigation"
            />
          </Flex>
          <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </Flex>
          <Flex display={{ base: 'none', lg: 'flex' }} mr={10}>
            <DesktopNav />
          </Flex>
          <Stack
            flex={{ base: 1, md: 0 }}
            justify="flex-end"
            direction="row"
            spacing={20}
          >
            <CustomButton to="/contact" className="header-contact-btn">
              CONTACT
            </CustomButton>
          </Stack>
        </Container>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
}

const DesktopNav = () => {
  const popoverContentBgColor = useColorModeValue('white', 'gray.800');

  return (
    <Stack className="menu-bar" direction="row" p={4} spacing={4}>
      {NAV_ITEMS.map((navItem, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Box key={index}>
          <Popover trigger="hover" placement="bottom-start">
            <PopoverTrigger>
              {navItem.href ? (
                <Link to={navItem.href}>
                  <h4 className="menu-bar--item">{navItem.label}</h4>
                </Link>
              ) : (
                <h4 className="menu-bar--item">{navItem.label}</h4>
              )}
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow="xl"
                bg={popoverContentBgColor}
                p={4}
                minW="100vw"
              >
                <Container maxW="container.lg" className="stack-nav" p={2}>
                  {navItem.children.map((child, i) => (
                    <DesktopSubNav key={i} {...child} />
                  ))}
                </Container>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, items, href }) => {
  return (
    <Stack p={0} className="stack-item" direction="row" align="center">
      <Box>
        {href ? (
          <Link className="stack-item--link-label" to={href}>
            {label}
          </Link>
        ) : (
          <Text className="stack-item--label">{label}</Text>
        )}

        <Flex display="flex" flexDir="column">
          {items
            ? items.map((item) => (
                <Link
                  to={item.href}
                  key={item.href}
                  transition="all .3s ease"
                  fontWeight={500}
                >
                  {item.subLabel}
                </Link>
              ))
            : null}
        </Flex>
      </Box>
    </Stack>
  );
};

const MobileNav = () => {
  return (
    <Stack
      bg={useColorModeValue('white', 'gray.800')}
      p={4}
      display={{ lg: 'none' }}
      className="mobile-nav-collapse"
    >
      <Accordion allowToggle="true">
        {NAV_ITEMS.map((navItem, index) => (
          <MobileNavItem key={index} {...navItem} />
        ))}
      </Accordion>

      <CustomButton marginTop={4} to="/contact">
        CONTACT
      </CustomButton>
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }) => {
  return (
    <AccordionItem>
      <h3>
        {children ? (
          <AccordionButton>
            <Box flex="1" textAlign="left">
              {label}
            </Box>
            <AccordionIcon />
          </AccordionButton>
        ) : (
          <Link className="mobile-title" key={href} py={2} to={href ?? '/'}>
            {label}
          </Link>
        )}
      </h3>
      <AccordionPanel pb={4}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle="solid"
          borderColor="#044606"
          align="start"
        >
          {children &&
            children.map((child) => (
              <>
                <Link
                  className="mobile-child-title"
                  key={child.href}
                  py={2}
                  to={child.href ?? '/'}
                >
                  {child.label}
                </Link>
                <div key={child.label} className="mobile-child-subitem">
                  {child.items
                    ? child.items.map((item, dex) => (
                        <Link
                          className="mobile-subitem"
                          key={dex}
                          py={2}
                          to={item.href ?? '/'}
                        >
                          {item.subLabel}
                        </Link>
                      ))
                    : ''}
                </div>
              </>
            ))}
        </Stack>
      </AccordionPanel>
    </AccordionItem>
  );
};
